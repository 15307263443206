import { Routes, Route } from 'react-router-dom'
import React from 'react';
import {ModalProvider} from "./context";
import Configurator from "./pages/Configurator";

function App() {
  return (
      <ModalProvider>
          <div id="wrapper" className="wrapper">
              <Routes>
                  <Route path="/" element={<Configurator />} />
              </Routes>
          </div>
      </ModalProvider>
  );
}

export default App;
